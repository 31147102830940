<template>
    <div>
        <router-view />
    </div>
</template>

<script>
  export default {
    name: 'index',
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
